import Head from "next/head";
import parse from "html-react-parser";
import Script from "next/script";
import { useRouter } from 'next/router';

const HeadTitle = ({ seo }) => {

  const path = useRouter().asPath;

  var fullHead = seo?.fullHead ? parse(seo.fullHead) : null;
  if (fullHead) {
    fullHead = fullHead.map((item) => {
      if (item.type === "meta" && item.props.property === "og:url") {
        var pos = item.props?.content?.indexOf("api.");
        var pos_1 = item.props?.content?.lastIndexOf("pose.vn");
        return { ...item, props: { ...item.props, content: 'https://' + item.props.content.substring(pos + 4, pos_1 + 7) + path } };
      } else {
        return item;
      }
      // return item.type === "meta" && item.props.property === "og:url" ? { ...item, props: { ...item.props, content: item.props.content.replace("api.", "").substring(0, pos) + path } } : item;
    });
    fullHead = fullHead.map((item) => {
      var pos = item.props?.href?.lastIndexOf("/");
      if (item.type === "link" && item.props.rel === "canonical") {
        var pos = item.props?.href?.indexOf("api.");
        var pos_1 = item.props?.href?.lastIndexOf("pose.vn");
        return { ...item, props: { ...item.props, href: 'https://' + item.props.href.substring(pos + 4, pos_1 + 7) + path } };
      } else {
        return item;
      }
    });
  }

  return (
    <>
      {/* <Script
        id="gtm-script"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script
        id="gtm-config"
        strategy="afterInteractive"
      > 
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
          });
        `}
      </Script> */}
      <Head>
        <title>{seo?.title}</title>
        <meta property="article:tag" content={seo?.title} />
        <meta name="description" content={seo?.opengraphDescription} />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/cropped-favicon-nightlife-180x180.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/cropped-favicon-nightlife-32x32.png" />
        {fullHead && fullHead}
        <meta name="google-site-verification" content="_cxFkuH9xOsr7vqDE0ENNZC7dlh7kvqBF2AZJWKU_4w" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Head>
      <Script
      id="format-script"
      strategy="lazyOnload"
      >
        {`
          if(document.querySelector("[class='yoast-schema-graph']")) {
            const script = document.createElement('script');
            script.type = 'application/ld+json';
            script.className = 'yoast-schema-graph';
            script.innerHTML = document.querySelector("[class='yoast-schema-graph']").innerHTML.replaceAll('api.pose.vn', 'pose.vn');
            document.querySelector("[class='yoast-schema-graph']").replaceWith(script);
        }
        `}
      </Script>
    </>
  );
}

export default HeadTitle;