import React from "react";
import Image from "next/image";
import Nav from "./Nav";

const HeaderFive = ({ keyword, slug }) => {

  return (
    <>
      <header className="header axil-header header-style-4 header-light header-sticky">
        <div className="container">
          <div className="row justify-content-between align-items-center mt_lg--10 mt_md--10 mt_sm--10">
            <div className="col-xl-9 col-lg-4 col-md-4 col-4">
              <div className="wrapper d-block d-sm-flex flex-wrap align-items-center justify-content-center justify-content-lg-start justify-content-md-start justify-content-sm-start">
                <div className="logo">
                  <a href="/" aria-label="Blogar logo">
                    <Image
                      className="dark-logo"
                      width={88}
                      height={40}
                      src="/images/logo/logo.svg"
                      alt="Pose logo"
                    />
                  </a>
                </div>

                <div className="mainmenu-wrapper d-none d-xl-block">
                  <nav className="mainmenu-nav">
                    <Nav slug={slug} />
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-8 col-md-8 col-8">
              <div className="header-search d-flex flex-wrap align-items-center justify-content-center justify-content-xl-end">
                <form className="header-search-form" action={`/tim-kiem`} method="get">
                  <div className="axil-search form-group">
                    <button type="submit" className="search-button" aria-label="Tìm kiếm">
                      <i className="fal fa-search" />
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Tìm kiếm"
                      defaultValue={keyword}
                      name="keyword"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* Start Hamburger Menu  */}
          <div className="d-block d-xl-none">
            <nav className="mainmenu-nav">
              <Nav mobile={true} slug={slug} />
            </nav>
          </div>
          {/* End Hamburger Menu  */}
        </div>
      </header>
    </>
  );
};

export default HeaderFive;
