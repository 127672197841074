import { useState } from 'react';
import { useRouter } from 'next/router';

const Nav = ({ mobile, slug }) => {

  const { pathname } = useRouter();

  const [isHoverLifeStyle, setIsHoverLifeStyle] = useState(false);
  const [isHoverEntertainment, setIsHoverEntertainment] = useState(false);

  return (
    <ul className={`mainmenu ${mobile ? "mobile-menu" : ""}`}>
      <li className="menu-item-has-children">
        <a
          href="/"
          aria-label="Home"
          className={`${pathname === "/" ? "active" : ""}`}
        >
          <i className="fas fa-home" />
        </a>
      </li>
      <li className="menu-item-has-children">
        <a href="/chuyen-muc/hoa-hau" aria-label="Hoa hậu" style={{ fontSize: 16 }} className={`${slug?.includes("hoa-hau") ? "active" : ""}`}>Hoa hậu</a>
      </li>
      <li className="menu-item-has-children" onMouseOver={() => setIsHoverLifeStyle(true)} onMouseLeave={() => setIsHoverLifeStyle(false)}>
        <a href="/chuyen-muc/life-style" aria-label="Lifestyle" className={`${isHoverLifeStyle ? "hover" : ""} ${slug?.includes("life-style") || slug?.includes("khoe") || slug?.includes("lam-dep") || slug?.includes("thoi-trang") ? "active" : ""}`} style={{ fontSize: 16 }}>
          Lifestyle
          <i className={`fal ml--5 d-none d-md-inline ${isHoverLifeStyle ? "fa-angle-up" : "fa-angle-down"}`} style={{ fontSize: 16 }} />
        </a>
        <ul className="axil-submenu">
          <li>
            <a href="/chuyen-muc/khoe" aria-label="Khoẻ">
              <span data-text="Khoẻ">Khoẻ</span>
            </a>
          </li>
          <li>
            <a href="/chuyen-muc/lam-dep" aria-label="Làm đẹp">
              <span data-text="Làm đẹp">Làm đẹp</span>
            </a>
          </li>
          <li>
            <a href="/chuyen-muc/thoi-trang" aria-label="Thời trang">
              <span data-text="Thời trang">Thời trang</span>
            </a>
          </li>
        </ul>
      </li>
      <li className="menu-item-has-children" onMouseOver={() => setIsHoverEntertainment(true)} onMouseLeave={() => setIsHoverEntertainment(false)}>
        <a href="/chuyen-muc/giai-tri" aria-label="Giải trí" className={`${isHoverEntertainment ? "hover" : ""} ${slug?.includes("giai-tri") || slug?.includes("sao") || slug?.includes("phim-anh") || slug?.includes("tv-show") ? "active" : ""}`} style={{ fontSize: 16 }}>
          Giải trí
          <i className={`fal ml--5 d-none d-md-inline ${isHoverEntertainment ? "fa-angle-up" : "fa-angle-down"}`} style={{ fontSize: 16 }} />
        </a>
        <ul className="axil-submenu">
          <li>
            <a href="/chuyen-muc/sao" aria-label="Sao">
              <span data-text="Sao">Sao</span>
            </a>
          </li>
          <li>
            <a href="/chuyen-muc/phim-anh" aria-label="Phim ảnh">
              <span data-text="Phim ảnh">Phim ảnh</span>
            </a>
          </li>
          <li>
            <a href="/chuyen-muc/tv-show" aria-label="TV show">
              <span data-text="TV show">TV show</span>
            </a>
          </li>
        </ul>
      </li>
      {/* <li>
        <Link legacyBehavior href="/chuyen-muc/short-clip">
          <a aria-label="Shortclip" style={{ fontSize: 16}}>Shortclip</a>
        </Link>
      </li> */}
      <li className="menu-item-has-children">
        <a href="/ve-chung-toi" aria-label="Về Pose.vn" style={{ fontSize: 16 }} className={`${pathname?.includes("ve-chung-toi") ? "active" : ""}`}>Về Pose.vn</a>
      </li>
      {/* <li>
        <Link legacyBehavior href="/lien-he">
          <a aria-label="Liên hệ" className={`${slug?.includes("lien-he") ? "active" : ""}`}
            style={{ fontSize: 16 }}>Liên hệ</a>
        </Link>
      </li> */}
      <li className="menu-item-has-children">
        <a href="/lien-he" aria-label="Liên hệ" style={{ fontSize: 16 }} className={`${pathname?.includes("lien-he") ? "active" : ""}`}>Liên hệ</a>
      </li>
    </ul>
  );
}


export default Nav;
