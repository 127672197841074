import Link from "../../components/Link";
import Image from "next/image";

const FooterTwo = () => {
  return (
    <div className="axil-footer-area axil-footer-style-1 footer-variation-three bg-color-black mt--40">
      {/* Start Footer Top Area  */}
      <div className="footer-top d-none d-md-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="logo-dark" style={{ position: 'relative' }}>
                <Link legacyBehavior href={'/'}>
                  <a className="position-relative d-block" style={{ height: 60 }}>
                    <Image
                      src="/images/logo/logo-dark.svg"
                      alt="Logo Images"
                      fill
                      sizes="100%"
                      priority={true}
                    />
                  </a>
                </Link>
              </div>
              <div className="frame mt--30">
                <div className="div">
                  <p className="text-wrapper">CHỊU TRÁCH NHIỆM NỘI DUNG</p>
                  <div className="div-2">
                    <div className="text-wrapper-2">Bà Lê Thị Thu Hoà</div>
                    <div className="text-wrapper-3">Thông tin CEO</div>
                  </div>
                </div>
                <div className="div">
                  <div className="text-wrapper">HỢP TÁC TRUYỀN THÔNG</div>
                  <div className="div-3">
                    <i className="fas fa-phone-alt"></i>
                    <div className="text-wrapper-2">
                      <a href="tel:(+84) 909 901 497" style={{ color: 'white' }}>(+84) 909 901 497</a>
                    </div>
                  </div>
                  <div className="div-3">
                    <i className="fas fa-envelope"></i>
                    <div className="text-wrapper-2">
                      <a href="mailto:bookingpr@pose.vn" style={{ color: 'white' }}>bookingpr@pose.vn</a>
                    </div>
                  </div>
                </div>
                <div className="div">
                  <div className="text-wrapper">LIÊN HỆ QUẢNG CÁO</div>
                  <div className="div-3">
                    <i className="fas fa-phone-alt"></i>
                    <div className="text-wrapper-2">
                      <a href="tel:(+84) 909 901 497" style={{ color: 'white' }}>(+84) 909 901 497</a>
                    </div>
                  </div>
                  <div className="div-3">
                    <i className="fas fa-envelope"></i>
                    <div className="text-wrapper-2">
                      <a href="mailto:bookingpr@pose.vn" style={{ color: 'white' }}>
                        bookingpr@pose.vn
                      </a>
                    </div>
                  </div>
                  <button className="button mt--10"><div className="inter">Nhận báo giá</div></button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {/* Start Post List  */}
              <div className="d-flex mt_sm--15 align-items-center flex-wrap justify-content-around mt--25 mb--25 mb_md--15 mb_lg--15 pt--10 pb--10" style={{ borderTop: '1.5px solid #4E4E4E', borderBottom: '1.5px solid #4E4E4E' }}>
                <a href={'/chuyen-muc/hoa-hau'} aria-label="Hoa hậu">
                  <div className="text-wrapper-5" style={{ cursor: 'pointer' }}>Hoa hậu</div>
                </a>
                {/* <div className="text-wrapper-5">Shortclip</div> */}
                <a href={'/chuyen-muc/life-style'} aria-label="Lifestyle">
                  <div className="text-wrapper-5" style={{ cursor: 'pointer' }}>Lifestyle</div>
                </a>
                <a href={'/chuyen-muc/giai-tri'} aria-label="Giải trí">
                  <div className="text-wrapper-5" style={{ cursor: 'pointer' }}>Giải trí</div>
                </a>
                <a href={'/lien-he'} aria-label="Liên hệ" >
                  <div className="text-wrapper-5">Liên hệ</div>
                </a>
                <a href={'/ve-chung-toi'} aria-label="Về Pose.vn" className="text-wrapper-5">
                  <div className="text-wrapper-5">Về Pose.vn</div>
                </a>
              </div>
              {/* End Post List  */}
              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-12 col-12">
                  <div className="frame">
                    <div className="div">
                      <div className="div-7" style={{ marginTop: 0 }}>
                        <div className="text-wrapper">TRỤ SỞ TP.HCM</div>
                        <div className="div-8" style={{ marginTop: '0 !important', width: 'unset !important' }}>
                          <i className="fas fa-map-marker-alt"></i>
                          <div className="text-wrapper-3">Xem bản đồ</div>
                        </div>
                      </div>
                      <div className="div">
                        <div className="div-4">
                          <Image className="img-2" alt="img-hotel" src="/images/icons/hotel.svg" style={{ cursor: "pointer", width: 16, height: 16 }} width={16} height={16} />
                          <p className="p">Lầu 2 -Tòa nhà Hà Phan Số 17-19 Tôn Thất Tùng, Phường Phạm Ngũ Lão, Quận 1, TP. Hồ Chí Minh</p>
                        </div>
                        <div className="div-4">
                          <i className="fas fa-phone-alt"></i>
                          <div className="text-wrapper-2">
                            <a href="tel:(+84) 909 901 497" style={{ color: 'white' }}>(+84) 909 901 497</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="div-6">
                        <Image className="img" alt="img-21" src="/images/icons/component-21.svg" style={{ cursor: "pointer", width: 18, height: 19 }} width={18} height={19} />
                        <div className="text-wrapper-3">Đóng góp nội dung</div>
                      </div>
                      <div className="div-6">
                        <Image className="img" alt="img-22" src="/images/icons/component-22.svg" style={{ cursor: "pointer", width: 18, height: 19 }} width={18} height={19} />
                        <a href={'/chinh-sach-bao-mat'}
                          className="text-wrapper-4">Chính sách bảo mật</a>
                      </div>
                      <div className="div-6">
                        <Image className="img" alt="img-23" src="/images/icons/component-23.svg" style={{ cursor: "pointer", width: 18, height: 19 }} width={18} height={19} />
                        <div className="text-wrapper-4">Câu hỏi thường gặp</div>
                      </div>
                      <div className="div-6">
                        <Image className="img" alt="img-22" src="/images/icons/component-22.svg" style={{ cursor: "pointer", width: 18, height: 19 }} width={18} height={19} />
                        <a href={'/dieu-khoan-su-dung'}
                          className="text-wrapper-4">Điều khoản và điều kiện sử dụng</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-12 mt_lg--20 mt_md--20">
                  <div className="frame">
                    <div className="div-7" style={{ marginTop: '0' }}>
                      <div className="text-wrapper">KẾT NỐI VỚI POSE QUA</div>
                    </div>
                    <div className="div-7">
                      <Link legacyBehavior href="https://www.facebook.com/posevn" target="_blank">
                        <a aria-label="Facebook">
                          <Image style={{ cursor: "pointer", width: 48, height: 46 }} className="facebook" src="/images/icons/facebook.svg" alt="facebook" width={48} height={46} />
                        </a>
                      </Link>
                      <Link legacyBehavior href="https://www.tiktok.com/@pose.vn" target="_blank">
                        <a aria-label="Tiktok">
                          <Image style={{ cursor: "pointer" }} className="tiktok" src="/images/icons/tiktok.svg" alt="tiktok" width={40} height={40} />
                        </a>
                      </Link>

                      <Link legacyBehavior href="https://www.instagram.com/pose.vn" target="_blank">
                        <a aria-label="Instagram">
                          <Image style={{ cursor: "pointer" }} className="instagram" src="/images/icons/instagram.svg" alt="instagram" width={48} height={48} />
                        </a>
                      </Link>
                    </div>
                    <div className="div-7">

                      <Link legacyBehavior href="https://www.facebook.com/posevn" target="_blank">
                        <a aria-label="Facebook">
                          <Image style={{ cursor: "pointer", width: 282, height: 87 }} className="facebook" src="/images/icons/facebook-1.svg" alt="facebook-1" width={282} height={87} />
                        </a>
                      </Link>
                    </div>
                    <div className="div-7">
                      <Link legacyBehavior href="https://www.tiktok.com/@pose.vn" target="_blank">
                        <a aria-label="Tiktok">
                          <Image style={{ cursor: "pointer", width: 282, height: 87 }} className="tiktok" src="/images/icons/tiktok-2.svg" alt="tiktok-1" width={282} height={87} />
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="register-notification mt--27 mb--30">
                <p className="text-wrapper mb--0">Nhận thông báo qua email</p>
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <div className="frame border-1">
                <p className="div-9 mb--0">Tự hào là đơn vị tài trợ giải<br />“Best Catwalk” cuộc thi</p>
                <Image className="image" src="/images/icons/image-2.svg" alt="Logo Images" width={246} height={67} style={{ width: 246, height: 67 }} />
              </div>
              <div className="mt--20" style={{ color: 'white', textAlign: 'center' }}>
                Trang tin tức giải trí thuộc
              </div>
              <div>
                <div className="position-relative" style={{ minHeight: 100, width: 150, margin: 'auto' }}>
                  <Image src="/images/icons/image-6.png" alt="Logo Images" fill style={{ objectFit: 'contain' }} sizes="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-top d-block d-md-none">
        <div className="mobile-frame">
          <div className="mobile-div">
            <div className="logo-dark" style={{ position: 'relative', width: 200 }}>
              <Link legacyBehavior href={'/'}>
                <a className="position-relative d-block" style={{ height: 60 }}>
                  <Image
                    src="/images/logo/logo-dark.svg"
                    alt="Logo Images"
                    fill
                    sizes="100%"
                    priority={true}
                  />
                </a>
              </Link>
            </div>
            <div className="register-notification " style={{ width: 250 }}>
              <p className="text-wrapper mb--0">Nhận thông báo qua email</p>
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </div>
          </div>
          <div className="mobile-navbar">
            <a href={'/chuyen-muc/hoa-hau'} aria-label="Hoa hậu">
              <div className="mobile-text-wrapper-2" style={{ cursor: 'pointer' }}>Hoa hậu</div>
            </a>
            {/* <div className="text-wrapper-5">Shortclip</div> */}
            <a href={'/chuyen-muc/life-style'} aria-label="Lifestyle">
              <div className="mobile-text-wrapper-2" style={{ cursor: 'pointer' }}>Lifestyle</div>
            </a>
            <a href={'/chuyen-muc/giai-tri'} aria-label="Giải trí">
              <div className="mobile-text-wrapper-2" style={{ cursor: 'pointer' }}>Giải trí</div>
            </a>
            <a href={'/lien-he'} aria-label="Liên hệ" className="mobile-text-wrapper-2">Liên hệ</a>
            <a href={'/ve-chung-toi'} aria-label="Về Pose.vn"
              className="mobile-text-wrapper-2">Về Pose.vn</a>
          </div>
          <div className="mobile-div-4">
            <div className="mobile-div-5">
              <div className="mobile-div-6">
                <div className="mobile-div-7">
                  <p className="mobile-text-wrapper-3">CHỊU TRÁCH NHIỆM NỘI DUNG</p>
                  <div className="mobile-div-8">
                    <div className="mobile-text-wrapper-4">Bà Lê Thị Thu Hoà</div>
                    <div className="mobile-text-wrapper-5">Thông tin CEO</div>
                  </div>
                </div>
                <div className="mobile-div-7">
                  <div className="mobile-text-wrapper-3">HỢP TÁC TRUYỀN THÔNG</div>
                  <div className="mobile-div-9">
                    <i className="fas fa-phone-alt" style={{ fontSize: 14 }}></i>
                    <div className="mobile-text-wrapper-6">
                      <a href="tel:(+84) 909 901 497" style={{ color: 'white' }}>(+84) 909 901 497</a>
                    </div>
                  </div>
                  <div className="mobile-div-9">
                    <i className="fas fa-envelope"></i>
                    <a href="mailto:bookingpr@pose.vn" className="mobile-text-wrapper-6">bookingpr@pose.vn</a>
                  </div>
                </div>
                <div className="mobile-div-7">
                  <div className="mobile-text-wrapper-3">LIÊN HỆ QUẢNG CÁO</div>
                  <div className="mobile-div-10">
                    <i className="fas fa-phone-alt"></i>
                    <div className="mobile-text-wrapper-4">
                      <a href="tel:(+84) 909 901 497" style={{ color: 'white' }}>(+84) 909 901 497</a>
                    </div>
                  </div>
                  <div className="mobile-div-10">
                    <i className="fas fa-envelope"></i>
                    <div className="mobile-text-wrapper-4">
                      <a href="mailto:bookingpr@pose.vn" style={{ color: 'white' }}>
                        bookingpr@pose.vn
                      </a>
                    </div>
                  </div>
                  <button className="mobile-button mt--10"><div className="mobile-inter">Nhận báo giá</div></button>
                </div>
              </div>
              <div className="mobile-div-6">
                <div className="mobile-div-7">
                  <div className="mobile-div-11">
                    <div className="mobile-text-wrapper-7">TRỤ SỞ TP.HCM</div>
                    <div className="mobile-div-12">
                      <i className="fas fa-map-marker-alt"></i>
                      <div className="mobile-text-wrapper-8">Xem bản đồ</div>
                    </div>
                  </div>
                  <div className="mobile-div-7">
                    <div className="mobile-div-9">
                      <Image className="img-2" alt="img-hotel" src="/images/icons/hotel.svg" style={{ cursor: "pointer", width: 16, height: 16 }} width={16} height={16} />
                      <p className="mobile-text-wrapper-6">
                        Lầu 2 -Tòa nhà Hà Phan Số 17-19 Tôn Thất Tùng, Phường Phạm Ngũ Lão, Quận 1, TP. Hồ Chí Minh
                      </p>
                    </div>
                    <div className="mobile-div-9"><i className="fas fa-phone-alt"></i><div className="mobile-text-wrapper-6"><a href="tel:(+84) 909 901 497" style={{ color: 'white' }}>(+84) 909 901 497</a></div></div>
                  </div>
                </div>
                <div className="mobile-div-13">
                  <div className="mobile-div-14">
                    <Image className="img" alt="img-21" src="/images/icons/component-21.svg" style={{ cursor: "pointer", width: 18, height: 19 }} width={18} height={19} />
                    <div className="mobile-text-wrapper-9">Đóng góp nội dung</div>
                  </div>
                  <div className="mobile-div-14">
                    <Image className="img" alt="img-22" src="/images/icons/component-22.svg" style={{ cursor: "pointer", width: 18, height: 19 }} width={18} height={19} />
                    <div className="mobile-text-wrapper-9">
                      <a href={'/chinh-sach-bao-mat'}
                        className="mobile-text-wrapper-4">Chính sách bảo mật</a>
                    </div>
                  </div>
                  <div className="mobile-div-14">
                    <Image className="img" alt="img-23" src="/images/icons/component-23.svg" style={{ cursor: "pointer", width: 18, height: 19 }} width={18} height={19} />
                    <div className="mobile-text-wrapper-9">
                      <a href={'/dieu-khoan-su-dung'}
                        className="mobile-text-wrapper-4">Câu hỏi thường gặp</a>
                    </div>
                  </div>
                  <div className="mobile-div-14">
                    <Image className="img" alt="img-22" src="/images/icons/component-22.svg" style={{ cursor: "pointer", width: 18, height: 19 }} width={18} height={19} />
                    <p className="mobile-text-wrapper-9">
                      <a href={'/dieu-khoan-su-dung'}
                        className="mobile-text-wrapper-4">Điều khoản và điều kiện sử dụng</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-div-15">
              <p className="mobile-text-wrapper-7 mt--10 mb--10">KẾT NỐI VỚI POSE QUA</p>
              <div className="div-7">

                <Link legacyBehavior href="https://www.facebook.com/posevn" target="_blank">
                  <a aria-label="Tiktok">
                    <Image className="facebook" src="/images/icons/facebook.svg" alt="facebook" width={48} height={48} />
                  </a>
                </Link>

                <Link legacyBehavior href="https://www.tiktok.com/@pose.vn" target="_blank">
                  <a aria-label="Tiktok">
                    <Image className="tiktok" src="/images/icons/tiktok.svg" alt="tiktok" width={40} height={40} />
                  </a>
                </Link>

                <Link legacyBehavior href="https://www.instagram.com/pose.vn" target="_blank">
                  <a aria-label="Tiktok">
                    <Image className="instagram" src="/images/icons/instagram.svg" alt="instagram" width={48} height={48} />
                  </a>
                </Link>
              </div>
              <div className="div-7">
                <Link legacyBehavior href="https://www.facebook.com/posevn" target="_blank">
                  <a aria-label="Facebook">
                    <Image style={{ cursor: "pointer", width: 282, height: 87 }} className="facebook" src="/images/icons/facebook-1.svg" alt="facebook-1" width={282} height={87} />
                  </a>
                </Link>
              </div>
              <div className="div-7">
                <Link legacyBehavior href="https://www.tiktok.com/@pose.vn" target="_blank">
                  <a aria-label="Tiktok">
                    <Image style={{ cursor: "pointer", width: 282, height: 87 }} className="tiktok" src="/images/icons/tiktok-2.svg" alt="tiktok-1" width={282} height={87} />
                  </a>
                </Link>
              </div>
              <div className="frame border-1">
                <p className="div-9 mb--0">Tự hào là đơn vị tài trợ giải<br />“Best Catwalk” cuộc thi</p>
                <Image className="image" src="/images/icons/image-2.svg" alt="Logo Images" width={246} height={67} style={{ width: 246, height: 67 }} />
              </div>
              <div className="mt--30" style={{ color: 'white', textAlign: 'center' }}>
                Trang tin tức giải trí thuộc
              </div>
              <div>
                <div className="position-relative" style={{ minHeight: 60, width: 110, margin: 'auto' }}>
                  <Image src="/images/icons/image-6.png" alt="Logo Images" fill style={{ objectFit: 'contain' }} sizes="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTwo;
